import React, { useState, useEffect } from "react";
import { Container, AppBar, Typography, Grow, Grid } from "@mui/material";
import momories from "./assets/images/memories.png";
import Posts from "./components/Posts/Posts";
import Form from "./components/Form/Form";
import "./App.css";
import * as api from "./api/index.js";
import FileBase from "react-file-base64";
import { GoogleLogin } from "react-google-login";

const getPosts = async () => {
  try {
    const { data } = await api.fetchPosts();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const createPost = async (post) => {
  try {
    const { data } = await api.createPost(post);
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

function App() {
  const [posts, setPosts] = useState(null);
  const [postData, setPostData] = useState({
    creator: "",
    title: "",
    message: "",
    tags: "",
    selectedFile: "",
  });
  useEffect(() => {
    const get = async () => {
      const posts = await getPosts();
      setPosts(posts);
    };
    get();
  }, []);
  console.log(posts);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await createPost(postData);
    console.log("res", res);
  };

  const responseGoogle = (response) => {
    console.log("???");
    console.log(response);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          name="creator"
          value={postData.creator}
          onChange={(e) =>
            setPostData({ ...postData, creator: e.target.value })
          }
        />
        <input
          name="title"
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        <input
          name="message"
          value={postData.message}
          onChange={(e) =>
            setPostData({ ...postData, message: e.target.value })
          }
        />
        <input
          name="tags"
          value={postData.tags}
          onChange={(e) => setPostData({ ...postData, tags: e.target.value })}
        />
        <div>
          <FileBase
            type="file"
            multiple={false}
            onDone={({ base64 }) =>
              setPostData({ ...postData, selectedFile: base64 })
            }
          />
        </div>
        <button type="submit">提交</button>
      </form>
      {/* {posts && <img src={posts[0]?.selectedFile}></img>} */}
      <GoogleLogin
        clientId="1090784850463-q9u09hnvh7oe8m6pl0f29t87p3f6ttj4.apps.googleusercontent.com"
        render={(renderProps) => (
          <button onClick={renderProps.onClick} disabled={renderProps.disabled}>
            This is my custom Google button
          </button>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
}

export default App;
